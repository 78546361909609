import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import RestApi from "../api/RestApi";

// Async thunk for list listVendorTxn by id
export const listVendorTxn = createAsyncThunk(
  "vendor/listVendorTxn",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      // Make request to the backend
      const { data } = await RestApi.get("/app/listVendorTxnId", config);
      return data;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const listUsers = createAsyncThunk(
  "vendor/listUsers",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      // Make request to the backend
      const { data } = await RestApi.get("/admin/listusers", config);
      return data;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error);
      }
      return rejectWithValue(error.message);
    }
  }
);
// Async thunk for create User Transaction

export const createUserTxn = createAsyncThunk(
  "vendor/createUserTxn",
  async (formData, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      // Make request to the backend
      const { data } = await RestApi.post(
        "/app/createUserTxn",
        formData,
        config
      );
      return data.message;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error);
      }
      return rejectWithValue(error.message);
    }
  }
);

// Auth slice with initial state
const txnSlice = createSlice({
  name: "txn",
  initialState: {
    loading: false,
    vendortxn: null,
    usersList: null,
    error: null,
    success: null,
  },
  reducers: {
    clearError: (state) => {
      state.success = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch vendor transactions by id
      .addCase(listVendorTxn.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(listVendorTxn.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.vendortxn = payload;
      })
      .addCase(listVendorTxn.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      // Fetch vendor transactions by id
      .addCase(listUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(listUsers.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.usersList = payload;
      })
      .addCase(listUsers.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })

      // to create user transaction
      .addCase(createUserTxn.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createUserTxn.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = payload;
      })
      .addCase(createUserTxn.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

// Export actions
export const { clearError } = txnSlice.actions;
export default txnSlice.reducer;
