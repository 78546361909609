// authSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import RestApi from "../api/RestApi";

// Async thunk for update kyc details
export const addMoney = createAsyncThunk(
  "vendor/addMoney",
  async (formData, { getState, rejectWithValue }) => {
    console.log(formData);
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      // Make request to the backend
      const { data } = await RestApi.post(
        "/app/addVendorFunds",
        formData,
        config
      );

      return data.message;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error);
      }
      return rejectWithValue(error.message);
    }
  }
);

// Async thunk for list funds by id
export const fetchFunds = createAsyncThunk(
  "vendor/fetchFunds",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };

      // Make request to the backend
      const { data } = await RestApi.get("/app/listVendorFundsbyId", config);
      return data;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error);
      }
      return rejectWithValue(error.message);
    }
  }
);

// Async thunk for withdrwal funds
export const withdrawFund = createAsyncThunk(
  "vendor/withdrawFund",
  async (formData, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      // Make request to the backend
      const { data } = await RestApi.post(
        "/app/withdrawVendorFunds",
        formData,
        config
      );
      return data.message;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error);
      }
      return rejectWithValue(error.message);
    }
  }
);

// Auth slice with initial state
const fundSlice = createSlice({
  name: "funds",
  initialState: {
    loading: false,
    sponserDetali: null,
    vendortxn: null,
    funds: null,
    list: null,
    error: null,
    success: null,
  },
  reducers: {
    clearError: (state) => {
      state.success = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Add Funds to money
      .addCase(addMoney.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(addMoney.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = payload?.message;
      })
      .addCase(addMoney.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      // Fetch funds by id
      .addCase(fetchFunds.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(fetchFunds.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.funds = payload;
      })
      .addCase(fetchFunds.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

// Export actions
export const { clearError } = fundSlice.actions;
export default fundSlice.reducer;
