import { Home, Person } from "@mui/icons-material";
import { lazy } from "react";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";

const IndexPage = lazy(() => import("../pages/app"));
const UserPage = lazy(() => import("../pages/user"));
const Funds = lazy(() => import("../pages/Funds"));
const Offline = lazy(() => import("../pages/Offline"));
const Transactions = lazy(() => import("../pages/Transactions"));

export const appRoutes = [
  {
    group: "Dashboard",
    title: "Dashboard",
    path: "",
    element: <IndexPage />,
    icon: <Home />,
  },
  {
    group: "Dashboard",
    title: "Profile",
    path: "users",
    element: <UserPage />,
    icon: <Person />,
  },

  {
    group: "Offline store",
    title: "Offline Store",
    path: "offline",
    element: <Offline />,
    icon: <FactCheckIcon />,
  },
  {
    group: "Transactions",
    title: "Transactions",
    path: "transaction",
    element: <Transactions />,
    icon: <LocalAtmIcon />,
  },
  {
    group: "Wallet",
    title: "Wallet History",
    path: "funds",
    element: <Funds />,
    icon: <LocalAtmIcon />,
  },
];
