import { lazy } from "react";
import { v4 as uuidv } from "uuid";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";

import { appRoutes } from "./routes/config";

import { useScrollToTop } from "./hooks/use-scroll-to-top";
// toast
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
//app layout
import DashboardLayout from "./layouts/dashboard";
import ProtectedRoute from "./routes/ProtectedRoute";
import UnProtectedRoute from "./routes/UnProtectedRoute";

const RegisterPage = lazy(() => import("./pages/auth/Register"));
const LoginPage = lazy(() => import("./pages/auth/login"));
const Page404 = lazy(() => import("./pages/page-not-found"));
const ForgotPassword = lazy(() => import("./pages/auth/ForgotPassword"));

export default function App() {
  useScrollToTop();
  const { userInfo } = useSelector((state) => state.auth);

  return (
    <>
      <Routes>
        <Route
          path="/register/*"
          element={
            <UnProtectedRoute>
              <RegisterPage />
            </UnProtectedRoute>
          }
        />
        <Route
          path="/login"
          element={
            <UnProtectedRoute>
              <LoginPage />
            </UnProtectedRoute>
          }
        />
        <Route path="/updatePassword" element={<ForgotPassword />} />

        <Route
          path="/"
          element={
            <ProtectedRoute>
              <DashboardLayout />
            </ProtectedRoute>
          }
        >
          {userInfo !== null
            ? appRoutes?.map((route) => (
                <Route
                  key={uuidv()}
                  path={route.path}
                  element={route.element}
                />
              ))
            : null}
        </Route>
        <Route path="*" element={<Page404 />} />
      </Routes>
      {/* tost */}
      <ToastContainer
        position="bottom-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
    </>
  );
}
