import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import RestApi from "../api/RestApi";

// Async thunk for Upline users
export const createVendorStore = createAsyncThunk(
  "vendor/createVendorStore",
  async (formData, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      // Make request to the backend
      const { data } = await RestApi.post("/app/vendorStore", formData, config);
      return data;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const listVendorStore = createAsyncThunk(
  "vendor/listVendorStore",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      // Make request to the backend
      const { data } = await RestApi.get("/app/listvendorStore", config);
      return data;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error);
      }
      return rejectWithValue(error.message);
    }
  }
);
// Async thunk for downline users
export const vendorKycVerifications = createAsyncThunk(
  "user/vendorKycVerifications",
  async (formData, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      // Make request to the backend
      const { data } = await RestApi.post(
        "/app/vendorKycVerifications",
        formData,
        config
      );
      return data;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const listvendorKyc = createAsyncThunk(
  "vendor/listvendorKyc",
  async (_, { getState, rejectWithValue }) => {
    try {
      const { userInfo } = getState().auth;
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      // Make request to the backend
      const { data } = await RestApi.get("/app/listvendorKyc", config);
      return data;
    } catch (error) {
      // Return custom error message from the API if any
      if (error.response && error.response.data.error) {
        return rejectWithValue(error.response.data.error);
      }
      return rejectWithValue(error.message);
    }
  }
);
// Auth slice with initial state
const vendorSlice = createSlice({
  name: "vendor",
  initialState: {
    loading: false,
    kycDetails: null,
    storeDetails: null,
    error: null,
    success: null,
  },
  reducers: {
    clearError: (state) => {
      state.success = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createVendorStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(createVendorStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = payload?.message;
      })
      .addCase(createVendorStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(listVendorStore.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(listVendorStore.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.storeDetails = payload;
      })
      .addCase(listVendorStore.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(vendorKycVerifications.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(vendorKycVerifications.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.success = payload;
      })
      .addCase(vendorKycVerifications.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(listvendorKyc.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = null;
      })
      .addCase(listvendorKyc.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.kycDetails = payload;
      })
      .addCase(listvendorKyc.rejected, (state, { payload }) => {
        state.loading = false;
        state.error = payload;
      });
  },
});

// Export actions
export const { clearError } = vendorSlice.actions;
export default vendorSlice.reducer;
