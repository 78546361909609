import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./authSlice";
import vendorReducer from "./vendorSlice";
import fundReducer from "./fundSlice";
import txnReducer from "./txnSlice";
const store = configureStore({
  reducer: {
    auth: authReducer,
    vendor: vendorReducer,
    funds: fundReducer,
    txn: txnReducer,
  },
});
export default store;
